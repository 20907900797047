import React from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import Pricing from '@clients/components/billing/pricing';

// eslint-disable-next-line
const LoginButtonNoSSR = dynamic(() => import('../clients/components/LoginButton'), {
  ssr: false,
  loading: () => <p>Loading</p>,
});

function HomePage() {
  return (
    <div className="body-wrap boxed-container">
      <Head>
        <title>Swissknife</title>
        <link
          href="https://fonts.googleapis.com/css?family=Heebo:400,500,700|Playfair+Display:700"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="/landing.css" />
      </Head>
      <header className="site-header">
        <div className="container">
          <div className="site-header-inner">
            <div className="brand header-brand">
              <h1 className="m-0">
                <img src="/logo.png" alt="SwissKnife" style={{ height: '1em' }} />
              </h1>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="hero">
          <div className="container">
            <div className="hero-inner">
              <div className="hero-copy">
                <h1 className="hero-title mt-0">Give your CircleCI Workflows a boost</h1>
                <p className="hero-paragraph">
                  Swissknife lets you manage and trigger your workflows on CircleCI easily,
                  especially sharing flows across your organization.
                </p>
                <div className="hero-cta">
                  <LoginButtonNoSSR />
                </div>
              </div>
              <div className="hero-app">
                <div className="hero-app-illustration">
                  <svg width="999" height="931" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        x1="92.827%"
                        y1="0%"
                        x2="53.422%"
                        y2="80.087%"
                        id="hero-shape-a"
                      >
                        <stop stopColor="#F9425F" offset="0%" />
                        <stop stopColor="#F97C58" stopOpacity="0" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="92.827%"
                        y1="0%"
                        x2="53.406%"
                        y2="80.12%"
                        id="hero-shape-b"
                      >
                        <stop stopColor="#47A1F9" offset="0%" />
                        <stop stopColor="#F9425F" stopOpacity="0" offset="80.532%" />
                        <stop stopColor="#FDFFDA" stopOpacity="0" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="8.685%"
                        y1="23.733%"
                        x2="85.808%"
                        y2="82.837%"
                        id="hero-shape-c"
                      >
                        <stop stopColor="#FFF" stopOpacity=".48" offset="0%" />
                        <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="79.483%"
                        y1="15.903%"
                        x2="38.42%"
                        y2="70.124%"
                        id="hero-shape-d"
                      >
                        <stop stopColor="#47A1F9" offset="0%" />
                        <stop stopColor="#FDFFDA" stopOpacity="0" offset="100%" />
                      </linearGradient>
                      <linearGradient
                        x1="99.037%"
                        y1="26.963%"
                        x2="24.582%"
                        y2="78.557%"
                        id="hero-shape-e"
                      >
                        <stop stopColor="#FDFFDA" stopOpacity=".64" offset="0%" />
                        <stop stopColor="#F97C58" stopOpacity=".24" offset="42.952%" />
                        <stop stopColor="#F9425F" stopOpacity="0" offset="100%" />
                      </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <g className="hero-shape-top">
                        <g
                          className="is-moving-object is-translating"
                          data-translating-factor="280"
                        >
                          <path
                            d="M680.188 0c-23.36 69.79-58.473 98.3-105.34 85.531-70.301-19.152-189.723-21.734-252.399 91.442-62.676 113.175-144.097 167.832-215.195 118.57C59.855 262.702 24.104 287.85 0 370.988L306.184 566.41c207.164-4.242 305.67-51.612 295.52-142.11-10.152-90.497 34.533-163.55 134.054-219.16l4.512-119.609L680.188 0z"
                            fill="url(#hero-shape-a)"
                            transform="translate(1)"
                          />
                        </g>
                        <g
                          className="is-moving-object is-translating"
                          data-translating-factor="100"
                        >
                          <path
                            d="M817.188 222c-23.36 69.79-58.473 98.3-105.34 85.531-70.301-19.152-189.723-21.734-252.399 91.442-62.676 113.175-144.097 167.832-215.195 118.57-47.399-32.841-83.15-7.693-107.254 75.445L443.184 788.41c207.164-4.242 305.67-51.612 295.52-142.11-10.152-90.497 34.533-163.55 134.054-219.16l4.512-119.609L817.188 222z"
                            fill="url(#hero-shape-b)"
                            transform="rotate(-53 507.635 504.202)"
                          />
                        </g>
                      </g>
                      <g transform="translate(191 416)">
                        <g className="is-moving-object is-translating" data-translating-factor="50">
                          <circle fill="url(#hero-shape-c)" cx="336" cy="190" r="190" />
                        </g>
                        <g className="is-moving-object is-translating" data-translating-factor="80">
                          <path
                            d="M683.766 133.043c-112.048-90.805-184.688-76.302-217.92 43.508-33.23 119.81-125.471 124.8-276.722 14.972-3.156 120.356 53.893 200.09 171.149 239.203 175.882 58.67 346.695-130.398 423.777-239.203 51.388-72.536 17.96-92.03-100.284-58.48z"
                            fill="url(#hero-shape-d)"
                          />
                        </g>
                        <g
                          className="is-moving-object is-translating"
                          data-translating-factor="100"
                        >
                          <path
                            d="M448.206 223.247c-97.52-122.943-154.274-117.426-170.26 16.55C261.958 373.775 169.717 378.766 1.222 254.77c-9.255 95.477 47.794 175.211 171.148 239.203 185.032 95.989 424.986-180.108 424.986-239.203 0-39.396-49.717-49.904-149.15-31.523z"
                            fill="url(#hero-shape-e)"
                            transform="matrix(-1 0 0 1 597.61 0)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="hero-app-dots hero-app-dots-1">
                  <svg width="124" height="75" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#FFF"
                        d="M33.392 0l3.624 1.667.984 3.53-1.158 3.36L33.392 10l-3.249-1.639L28 5.196l1.62-3.674z"
                      />
                      <path
                        fill="#7487A3"
                        d="M74.696 3l1.812.833L77 5.598l-.579 1.68L74.696 8l-1.624-.82L72 5.599l.81-1.837z"
                      />
                      <path
                        fill="#556B8B"
                        d="M40.696 70l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L38 72.599l.81-1.837z"
                      />
                      <path
                        fill="#7487A3"
                        d="M4.314 37l2.899 1.334L8 41.157l-.926 2.688L4.314 45l-2.6-1.31L0 41.156l1.295-2.94zM49.314 32l2.899 1.334.787 2.823-.926 2.688L49.314 40l-2.6-1.31L45 36.156l1.295-2.94z"
                      />
                      <path
                        fill="#556B8B"
                        d="M99.696 56l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L97 58.599l.81-1.837zM112.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L110 39.599l.81-1.837zM82.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L80 39.599l.81-1.837zM122.618 57l1.087.5.295 1.059-.347 1.008-1.035.433-.975-.492-.643-.95.486-1.101z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="hero-app-dots hero-app-dots-2">
                  <svg width="124" height="75" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#556B8B"
                        d="M33.392 0l3.624 1.667.984 3.53-1.158 3.36L33.392 10l-3.249-1.639L28 5.196l1.62-3.674zM74.696 3l1.812.833L77 5.598l-.579 1.68L74.696 8l-1.624-.82L72 5.599l.81-1.837zM40.696 70l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L38 72.599l.81-1.837zM4.314 37l2.899 1.334L8 41.157l-.926 2.688L4.314 45l-2.6-1.31L0 41.156l1.295-2.94zM49.314 32l2.899 1.334.787 2.823-.926 2.688L49.314 40l-2.6-1.31L45 36.156l1.295-2.94z"
                      />
                      <path
                        fill="#FFF"
                        d="M99.696 56l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L97 58.599l.81-1.837z"
                      />
                      <path
                        fill="#556B8B"
                        d="M112.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L110 39.599l.81-1.837z"
                      />
                      <path
                        fill="#FFF"
                        d="M82.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L80 39.599l.81-1.837z"
                      />
                      <path
                        fill="#556B8B"
                        d="M122.618 57l1.087.5.295 1.059-.347 1.008-1.035.433-.975-.492-.643-.95.486-1.101z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features section">
          <div className="container">
            <div className="features-inner section-inner has-bottom-divider">
              <h2 className="section-title mt-0">Features</h2>
              <div className="features-wrap">
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <h3 className="feature-title mt-24">Saved Workflow Triggers</h3>
                    <p className="text-sm mb-0">
                      Save your complex workflows (Recipes) and edit them with a nice UI so that you
                      can trigger them with a single click.
                    </p>
                  </div>
                </div>
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <h3 className="feature-title mt-24">Shared Recipes (paid)</h3>
                    <p className="text-sm mb-0">
                      Share recipes across your org so that your teammates can use them without
                      having to create them from scratch.
                    </p>
                  </div>
                </div>
                <div className="feature is-revealing">
                  <div className="feature-inner">
                    <h3 className="feature-title mt-24">
                      Custom Auto-cancel Redundant Builds (paid)
                    </h3>
                    <p className="text-sm mb-0">
                      CircleCI&apos;s auto cancel redundant build feature is great but it&apos;s not
                      customizable. Swissknife will allow you to customize it down to a branch and
                      workflow level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="media section">
          <div className="container-sm">
            <div className="media-inner section-inner">
              <div className="media-header text-center">
                <h2 className="section-title mt-0">Plans</h2>
                {/* <p className="section-paragraph mb-0">
                  Lorem ipsum is common placeholder text used to demonstrate the graphic elements of
                  a document or visual presentation.
                </p> */}
                <Pricing />
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="site-footer">
        <div className="container">
          <div className="site-footer-inner has-top-divider">
            <div className="brand footer-brand">
              <img src="/logo.png" alt="SwissKnife" style={{ height: '1em' }} />
            </div>
            <ul className="footer-links list-reset">
              <li>
                <a href="mailto:mail@swissknife.dev">Contact</a>
              </li>
            </ul>
            <ul className="footer-social-links list-reset">
              <li>
                <a href="https://twitter.com/swissknife">
                  <span className="screen-reader-text">Twitter</span>
                  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <div className="footer-copyright">&copy; 2023 Swissknife</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
