import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const marginZero = {
  margin: 0,
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'center',
  },
  group: {
    '&:after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
  featureSmall: {
    paddingBottom: theme.spacing(1),
    borderBottom: '1px dotted #817c7c',
  },
  marginZero,

  grid15: {
    border: '2px solid #5d4e65',
    minHeight: '400px',
    padding: '1.25em',
    position: 'relative',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    // @media screen and (min-width: 700px
    [theme.breakpoints.up('md')]: {
      '&': {
        float: 'left',
        width: '50%',
      },
      '&:nth-child(odd)': {
        clear: 'left',
      },
    },

    // @media screen and (min-width: 800px)
    [theme.breakpoints.up('lg')]: {
      '&:nth-child(3n+1)': {
        clear: 'left',
      },
      '&:nth-child(odd)': {
        clear: 'none',
      },
    },

    // @media screen and (min-width: 1120px)
    [theme.breakpoints.up('xlg')]: {
      '&': {
        width: '20%',
      },
      '&:nth-child(3n+1)': {
        clear: 'left',
      },
      '&:nth-child(odd)': {
        clear: 'none',
      },
    },

    '&:hover': {
      backgroundColor: 'rgb(83,69,91)',
      borderTop: '2px solid #ec7a37',
      borderBottom: '2px solid #ff4f69',
      boxShadow: '0px 0px 10px 0px rgba(50, 50, 50, 1)',
      transform: 'scale(1.025)',
      zIndex: '2',

      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgb(246,125,53)',
        top: '-2px',
        bottom: '-2px',
        width: '2px',
        right: '-2px',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgb(246,125,53)',
        top: '-2px',
        bottom: '-2px',
        width: '2px',
        left: '-2px',
      },
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  small: {
    fontSize: '0.6125em',
  },

  planTitle: {
    ...marginZero,
    fontSize: '1em',
    fontWeight: '400',
    margin: '0 0 0.5em',
    textTransform: 'uppercase',
  },

  planCost: {
    ...marginZero,
    fontSize: '1.5em',
    letterSpacing: '0.0625em',
    margin: '0 0 0.3333333333333333em',
  },
  planMain: {
    fontSize: '0.875em',
    margin: '0 0 1.5em',
    color: '#2fff57',
  },
  planList: {
    ...marginZero,
    color: '#eed4a0',
    fontSize: '0.75em',
    listStyleType: 'none',
    padding: 0,
    '& li': {
      margin: '0 0 0.8333333333333333em',
    },
  },
}));

function Pricing(props) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, classes.group, classes.pricingBox)}>
      <div className={classes.grid15}>
        <h2 className={classes.planTitle}>Basic</h2>
        <h3 className={classes.planCost}>
          <span className={classes.uppercase}>Free</span>
        </h3>
        <p className={classes.planMain}>Up to 10 personal recipes</p>
        <ul className={classes.planList}>
          <li>Unlimited Recipe triggers</li>
          <li>Limited Email Support</li>
        </ul>
        {props.showDowngradeButton && props.downgradeButton}
      </div>
      <div className={classes.grid15}>
        <h2 className={classes.planTitle}>Organization</h2>
        <h3 className={classes.planCost}>
          $5<span className={classes.small}>/mo</span>
        </h3>
        <div className={classes.planMain}>
          <p className={classes.featureSmall}>Unlimited shared recipes</p>
          <p className={classes.featureSmall}>Customizable Redundant Builds</p>
          <p className={classes.featureSmall}>Trigger when PR is opened</p>
          <p className={classes.featureSmall}>Managed queuing (coming soon)</p>
        </div>
        <ul className={classes.planList}>
          <li>Unlimited Recipe triggers</li>
          <li>Premium Support</li>
        </ul>
        {props.showUpgradeButton && props.upgradeButton}
        {props.showManageButton && props.manageButton}
      </div>
    </div>
  );
}

export default Pricing;
